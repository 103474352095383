
/* RESETFLOAT
----------------------------------------------------------------------------- */
.resetfloat:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
  margin: 0 !important;
  padding: 0 !important;
}

.resetfloat {
  display: inline-block;
}

.resetfloat {
  display: block;
  zoom: 1;
}

/* GENERAL
----------------------------------------------------------------------------- */

body {
  margin: 0;
  padding: 0;
  background: $body-bg;
  font-family: $font-family-sans-serif;
  font-size: $js-body-font-size;
  color: $js-body-font-color;
  min-width: $js-min-page-width;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @include clearfix();

  > form {
    position: relative;
  }
}

h1 {
  text-transform: $font-transform-header;
}

h2 {
  text-transform: $font-transform-header;
}

h3 {
  text-transform: $font-transform-header;
}

h4, h5, h6 {
  text-transform: $font-transform-header;
}

p {
  margin: 0 0 10px 0;
}

select {
  width: auto;
}

table, tbody, tfoot, thead, tr, th, td {
  border-collapse: collapse;
}

.watermark {
  color: lighten($js-body-font-color,20%);
}

#outer-wrapper {
  width: 100%;
}

#inner-wrapper {
  width: 100%;
  max-width: $js-max-page-width;
  margin: 0 auto;
  box-shadow: 0px 0px 6px #888888;
}

#content-wrapper {
  clear: both;
  //float: left;
  position: relative;
  width: 100%;
  max-width: $js-max-page-width;
  margin: 0 auto;
  @include clearfix();
}

.product-attributes-params {
  display: none;
}

.display-block {
  display: block;
}

//
//  Billy
//
a.billy-terms-fancy-popup, a.billy-terms-fancy-popup:visited {
  font-size: 8pt;
  font-weight: normal;
  color: $alert-color;
  cursor: pointer;
  display: inline;
  text-decoration: underline;
}

span.billycart, span.billycart:visited {
  font-size: 8pt;
  font-weight: normal;
  color: $alert-color;
  cursor: pointer;
  display: inline;
  text-decoration: underline;
}


//
//  Page links
//

a.page-link, a.page-link:visited {
  display: inline-block;
}

.page-link-wrapper a:hover span.link-text {
  background: none;
  text-decoration: underline;
}

.pagelink-selected a span.link-text {
  background: none;
}

.pagelink1 span.link-text {
  behavior: url(/SystemScripts/PIE.htc);
}

span.link-text {
}

span.link-spacer-right {
}


/* MANUFACTURER SELECTOR
----------------------------------------------------------------------------- */
.manufacturer-selector-wrapper {
  margin: 0 0 20px 0;
}

.manufacturer-selector-wrapper select {
  width: 100%;
}

/* CATEGORY AND MANUFACTURER DETAILS
----------------------------------------------------------------------------- */
.category-header-wrapper, .ManufacturerContentWrapperCssClass {
  clear: both;
  overflow: hidden;
  margin: 0 0 24px;
}

.category-page-image, .ManufacturerContentImageCssClass {
  float: left;
  margin: 0 10px 10px 0;
}


//
// Common styles paging
//

span.showing {
  display: inline-block;
  font-weight: normal;
  font-size: 9pt;
  color: black;
}

a.showing:hover {
  text-decoration: none !important;
}

.showing-highlight {
  color: red !important;
}

//
//  Stock Notification
//

#stock-notification-popup {
  background-color: #FFF;
  margin: 20px;
}

#stock-notification-popup-form-part {
  margin: 20px 0;
  clear: both;
}

.stock-notification-col-send {
  margin-top: 10px;
}

#stock-notification-message {
  text-align: center;
  margin-top: 70px;
  font-weight: bold;
}


#info-text-left-wrapper {
  display: inline-block;
  float: right;
  margin: 0;//14px 10px 10px 10px;
}

//
//
//
.page-list-wrapper {
  .fancybox-iframe-login {
    background: transparent;
    font-size: $base-font-size;
    padding: 0;
    margin: 0;
  }
  .mypages-text,
  .login-text {
    display: block;
    text-align: left;
  }
}

//
// WIDGETS
//

.box-wrapper {

}

.box-header {
  //background: $js-widget-box-header-background-color;
  padding: 10px 10px 0 0;
  behavior: url(/SystemScripts/PIE.htc);
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 8px;
  font-size: $base-font-size;
  font-weight: 700;
  text-transform: $font-transform-header;

  h2 {
    margin-bottom: 0;
  }

  span {
    margin-bottom: 2px;
    display: inline-block;
  }
}

.box-body {
  overflow: hidden;
  //background: $js-widget-box-background-color;
}

.box-footer {
  margin-bottom: 16px;
}

/* NEWS LIST */
.news-list-item {
  border-top: 1px solid #dfdfdf;
  padding: 10px 0;
}

.news-list-item:first-child {
  border: 0;
  padding: 0 0 10px 0;
}

.news-list-item-date {
  font-size: 0.85em;
  color: lighten($body-font-color, 30%);
  margin: 0;
  font-style: italic;
}

.news-list-item-image {
  margin: 0 0 10px 0;
}

.news-list-item-image img {
  width: 138px;
  max-width: 138px;
}

.news-list-item-text {
  font-size: 0.85em;
  margin: 0 0 10px 0;
}

.news-list-item-link {
  text-align: right;
  font-weight: bold;
}

/* OFFERS LIST */
.offers-list-header {
  display: none;
}

.offers-list-item-image {
  margin: 0 0 10px 0;
}

.offers-list-item-image img {
  width: 138px;
}

.offers-list-item-text p {
  font-size: 0.85em;
}

.offers-list-wrapper .product-price {
  float: left;
  margin: 4px 0 5px 0;
}

.offers-list-item-more-info {
  float: right;
}

.offers-list-item-image {
  position: relative;
}

.offers-list-item-image img {
  vertical-align: bottom;
}


/* TOP LIST */
.simple-product-list-item {
  overflow: hidden;
  border-top: 1px solid #dfdfdf;
  padding: 10px 0;
}

.simple-product-list-item:first-child {
  border: 0;
  padding: 0 0 10px 0;
}

.simple-product-list-item-text {
  font-size: 0.85em;
  margin: 0 0 2px 0;
}

.simple-product-list-item-text a {
  font-weight: bold;
}

.simple-product-list-item-image {
  float: left;
  padding: 0 10px 5px 0;
}

.simple-product-list-item-image img {
  width: 50px;
}

//
//  Info Message
//
.InfoMsgWrapper {
  margin: 5px 10px 5px 10px;
  position: relative;
}

.InfoMsgHeader {
  background: url(../images/InfoMsgHeader.gif) top center no-repeat;
  height: 7px;
  position: relative;
  top: 1px;
  font-size: 1px;

}

.InfoMsgHeaderRight {
  background-position: 90% 0%;
}

.InfoMsgHeaderLeft {
  background-position: 10% 0%;
}

.InfoMsgShadow {
  padding: 0px 2px 2px 0px;
  background: url(../images/ErrorMsgShadowBg.png) 3px 3px no-repeat;
}

.InfoMsgBodyWrapper {
  background: #ffffff url(../images/InfoMsgIcon.gif) 7px 8px no-repeat;
  border: 1px solid #003399;
}

.InfoMsgBodyHeader {
  text-align: right;
  height: 8px;
  overflow: hidden;
  font-size: 1px;
}

.InfoMsgBodyHeader img {
  cursor: pointer;
}

.InfoMsgContent {
  font-family: $body-font-family;
  font-size: 11px;
  color: #003399;
  padding: 0px 15px 10px 36px;
}


//
//  Error Page
//
.errorPage {
  margin: 0 10px;
}

.errorPageLogo {
  font-size: 5em;
  color: $alert-color;
  font-family: $font-family-header;
  text-transform: $font-transform-header;
  text-align: left;
}

.errorPageHeader {
  font-weight: bold;
  font-size: $js-body-font-size + 4;
}

.errorPageListHeader {
  margin-left: 0;
}

.errorPage ul {
  margin-left: 0;
  padding-left: 0;
}

.errorPage li {
  list-style-type: none;
}

//
//  Tooltips
//
.ui-tooltip, .arrow:after {
  background: black;
  border: 2px solid white;
}

.ui-tooltip {
  position: absolute;
  padding: 10px 20px;
  color: white;
  border-radius: 20px;
  font: bold 14px $button-font-family;
  text-transform: uppercase;
  box-shadow: 0 0 7px black;
}

.arrow {
  width: 70px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  bottom: -16px;
}

.arrow.top {
  top: -16px;
  bottom: auto;
}

.arrow.left {
  left: 20%;
}

.arrow:after {
  content: "";
  position: absolute;
  left: 20px;
  top: -20px;
  width: 25px;
  height: 25px;
  box-shadow: 6px 5px 9px -9px black;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  tranform: rotate(45deg);
}

.arrow.top:after {
  bottom: -20px;
  top: auto;
}

.ui-tooltip-content {
  padding: 0px;
  margin: 0px;
  vertical-align: middle;
}

#left-nav-placeholder {
  //display: none;
}

#bodyFastLoginBox {
  background: #888 !important;
  a.button.btnLoadProfile {
    background: $custom-orange !important;
  }
}
