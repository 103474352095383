/* CART AREA
----------------------------------------------------------------------------- */
.cart-area-wrapper {
  float: right;
  min-width: $js-cart-area-wrapper-min-width;
  max-width: $js-cart-area-wrapper-max-width;
  margin: $js-cart-area-wrapper-margin;
  background-color: $js-cart-area-wrapper-background-color;
  border: $js-cart-area-wrapper-border;
  min-height: $js-cart-area-wrapper-min-height;
  position: relative;
  clear: right;
}

.small-cart-wrapper {
  overflow: hidden;
  text-align: left;
  border: $js-small-cart-border;
  background: transparent;
}

.small-cart-header {
  display: none;
}

.small-cart-body {
  float: right;
  padding: 0; //$js-small-cart-padding-right 0 $js-small-cart-padding-left;
  cursor: pointer;
  transition: background 0.3s ease-out;
  color: $js-small-cart-color;
  background: white;
  height: 42px;
  
  &:hover,
  &:hover + * {
    //background: $js-cart-area-wrapper-background-hover-color;
    color: $custom-orange;
  }

  // Icon
  &::before {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    content: "\f07a";
    font-size: $js-small-cart-icon-size;
    float: left;
    display: block;
    height: 40px; //$js-small-cart-height;
    line-height: 40px; //$js-small-cart-height;
    margin-right: $js-small-cart-icon-margin-right;
    color: $custom-orange;
  }

  .title {
    display: none;
    float: left;
    font-weight: bold;
    padding-right: 4px;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
  }

}

.small-cart-items {
  float: left;
  height: $js-small-cart-height;
  line-height: $js-small-cart-height;
  font-weight: bold;

  // Number of items
  .small-cart-items-value {
    //display: none;
    margin-right: $js-small-cart-items-value-margin-right;
    font-size: $js-small-cart-font-size;
    float: left;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;
    margin-top: 10px;

    &::before {
      content: "(";
    }

    &::after {
      content: "):";
    }
  }

  .small-cart-items-label,
  .small-cart-items-suffix {
    display: none;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;
    font-size: $js-small-cart-font-size;
  }
}

.small-cart-sum {
  float: left;
  height: $js-small-cart-height;
  font-size: $js-small-cart-font-size;
  line-height: ($js-small-cart-height - 1);
  margin-top: 10px;

  //&::after {
  //  font-family: "FontAwesome";
  //  font-weight: normal;
  //  font-style: normal;
  //  text-decoration: inherit;
  //  content: "\f107";
  //  font-size: 16px;
  //  float: left;
  //  display: block;
  //  height: $js-small-cart-height;
  //  line-height: ($js-small-cart-height + 2);
  //  margin-left: 8px;
  //  margin-right: 12px;
  //}

  // Label "Total sum"
  .small-cart-sum-label {
    display: none;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;
    font-size: $js-small-cart-font-size;
  }

  // Total sum
  .small-cart-sum-value {
    float: left;
    height: $js-small-cart-height;
    line-height: $js-small-cart-height;
    font-size: $js-small-cart-font-size;
    font-weight: bold;
  }
}

.SmallCartButtons {
  float: right;
  height: $js-small-cart-height;
  transition: background 0.3s ease-out;
  display: none;
}

.SmallCartButtons div {
}

.CheckoutButton, .MyPagesButton {
  margin: 0 10px 0 0;
}

.small-cart-wrapper .KlarnaMonthlyCost {
  clear: both;
  padding: 2px 0;
  font-size: 11px;
  text-align: center;
  border-top: 1px dashed #dfdfdf;
}

#FreeFreight_Cart {
  //min-height: 21px;
}

.FreeFreight_Cart {
  background: $js-cart-freefreight-background;
  //border-top: $js-cart-freefreight-border-top;
  cursor: default;
  padding: 2px 0;
  text-align: center;
  font-size: 11px;
  max-height: 30px;
  overflow: hidden;
}

.FreeFreight_Cart_reached {
  background: $js-cart-freefreight-reached-background;
  //border-top: $js-cart-freefreight-reached-border-top;
  cursor: default;
  padding: 2px 0;
  text-align: center;
  font-size: 11px;
  max-height: 30px;
  overflow: hidden;
}

a.login-text.button {
  display: none;
}

// Fulhack to hide the buttons before we move them
a.login-text.button,
a.mypages-text.button {
  display: none;
}


//
//  Medium Down
//
@media #{$medium-down} {


}
//
//  Medium
//
@media #{$medium-only} {


}

//
//  Small
//
@media #{$small-only} {
}