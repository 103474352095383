//
//    Base Responsive
//
//    If Foundation-modules are activated, make sure to
//    add the JS-module to responsive-base-libraries.js
//
//    Sources for Foundation:
//    https://github.com/zurb/bower-foundation/releases/tag/5.5.2
//

// Reset CSS
@import "normalize";

// Foundation Settings
@import "foundation/global";
@import "foundation/functions";
@import "foundation/settings";

// Base Responsive Settings
@import "responsive-base/mixins";
@import "base_settings";

// Foundation Modules
@import "foundation/block-grid";
@import "foundation/visibility";
@import "foundation/flex-video";
@import "foundation/reveal";

// Base Responsive Components
@import "responsive-base/typography";
@import "responsive-base/general";
@import "responsive-base/buttons-v2";
@import "responsive-base/jquery-ui";
@import "responsive-base/columns";
@import "responsive-base/header";
@import "responsive-base/cart";
@import "responsive-base/footer";
@import "responsive-base/prices";
@import "responsive-base/free-freight";
@import "responsive-base/overlays";
@import "responsive-base/fancybox";
@import "responsive-base/left-category-menu";
@import "responsive-base/print";

// Base Responsive Pages
@import "responsive-base/page-listproducts";
@import "responsive-base/page-checkout";
@import "responsive-base/page-default";
@import "responsive-base/page-showproduct";
@import "responsive-base/page-showpage";
@import "responsive-base/page-responsive-checkout";
@import "responsive-base/page-responsive-order-confirm";
@import "responsive-base/page-showsearchresult";
@import "responsive-base/page-shownews";
@import "responsive-base/page-mypages";
@import "responsive-base/page-listmanufacturerproducts";
@import "responsive-base/page-speedorder";

// Base Responsive Modules
// The file '_modules.scss' is automatically generated by the Grunt 'sass_compile_imports' task
@import "responsive-base/modules";

// Base Responsive Media Queries
@import "responsive-base/media-queries"; //  Include media-queries last - this should be the last line of this file
