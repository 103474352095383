//
// CATEGORY MENU
//
@import "../../../scss/foundation/functions";
@import "../../../scss/base_settings";
@import "../../../scss/responsive-base/mixins";
@import "cat-nav-vert-right-settings";


//
// DESKTOP
//
@media #{$large-up} {

  // CAT-NAV BASE ELEMENT IS PLACEHOLDER/CONTAINER FOR ACTUAL NAVIGATION
  #cat-nav {
    clear: both;
    font-size: 0; /* remove spacing for inline-block */
    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    a {
      text-decoration: none;
    }
    .selected > a,
    .selected > a:hover {
      text-decoration: none;
    }
    // OUTER NAVIGATION WRAPPER
    .nav-bar {
      background: $js-cat-nav-background-color;
      padding: $js-cat-nav-padding;
      clear: both;
      width: 100%;
      font-size: 0; /* remove spacing for inline-block */
      position: relative;
      z-index: 3;
      opacity: 0;
    }
    //
    // LV1
    //
    ul.lv1 {
      position: relative;
      max-width: $js-max-page-width;
      margin: 0 auto;
      display: flex;
      @include clearfix();
    }

    li.lv1 {
      flex: auto;
      margin: $js-cat-nav-lv1-item-margin;
      position: relative;

      > a {
        height: $js-cat-nav-lv1-link-height;
        line-height: $js-cat-nav-lv1-link-height;
        padding: $js-cat-nav-lv1-link-padding;
        color: $js-cat-nav-lv1-link-color;
        font-weight: $js-cat-nav-lv1-link-font-weight;
        //border-right: $js-cat-nav-lv1-link-side-border;
        background-color: $js-cat-nav-lv1-link-background-color;
        font-family: $js-cat-nav-lv1-link-font-family;
        font-size: $js-cat-nav-lv1-link-font-size;
        transition: none;
        display: block;
        position: relative;
        text-decoration: $js-cat-nav-lv1-link-text-decoration;

        &:hover {
          text-decoration: $js-cat-nav-lv1-link-hover-text-decoration;
        }
      }

      &.first > a {
        //border-left: $js-cat-nav-lv1-link-side-border;
      }

      &:hover > a {
        background-color: $js-cat-nav-lv1-link-hover-background-color;
        color: $js-cat-nav-lv1-link-hover-color;
      }

      &.active > a {
        background-color: $js-cat-nav-lv1-link-active-background-color;
        color: $js-cat-nav-lv1-link-active-color;
      }

      &.active:hover > a {
        background-color: $js-cat-nav-lv1-link-active-hover-background-color;
        color: $js-cat-nav-lv1-link-active-hover-color;
      }
    }
    //
    // LV2
    //
    ul.lv2 {
      display: none;
      position: absolute;
      border: $js-cat-nav-lv2-container-border;
      padding: $js-cat-nav-lv2-container-padding;
      box-shadow: $js-cat-nav-lv2-container-shadow;
      background: $js-cat-nav-lv2-container-background-color;
      left: 0;
      right: initial;
      z-index: 2;
      top: $js-cat-nav-lv1-link-height;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -30px;
        bottom: -30px;
        right: -30px;
        z-index: -1;
      }
    }


    &.resizing ul.lv2 {
      display: block;
      top: -3000px;
    }

    &.hovered li.lv1:hover ul.lv2,
    .clicked-once ul.lv2 {
      display: block;
    }

    li.lv2 {
      display: block;
      vertical-align: top;
        width: auto;
        position: relative;
        margin: $js-cat-nav-lv2-item-margin;

      .positioner {
        position: absolute;
        right: $js-cat-nav-item-spacing * -1;
        top: 0;
        bottom: 0;
        display: none;
        padding-left: $js-cat-nav-item-spacing;
        background-color: $js-cat-nav-lv2-container-background-color;
      }

      &.first ul.lv3{
        border-top: none;
        top: 0;
      }

      &:last-child > a {
        border-bottom: 0 solid transparent;
      }

      > a {
        border-bottom: $js-cat-nav-lv2-item-border-bottom;
        font-weight: $js-cat-nav-lv2-link-font-weight;
        padding: $js-cat-nav-lv2-link-padding;
        font-size: $js-cat-nav-lv2-link-font-size;
        line-height: $js-cat-nav-lv2-link-line-height;
        color: $js-cat-nav-lv2-link-color;
        display: block;
        background: $js-cat-nav-lv2-link-background-color;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: $js-cat-nav-lv2-link-text-decoration;
        margin-top: $js-cat-nav-item-spacing;

        &:hover {
          color: $js-cat-nav-lv2-link-hover-color;
          background: $js-cat-nav-lv2-link-hover-background-color;
          text-decoration: $js-cat-nav-lv2-link-hover-text-decoration;
        }
      }

      &.active > a {
        color: $js-cat-nav-lv2-link-active-color;
        font-weight: $js-cat-nav-lv2-link-active-font-weight;
        background: $js-cat-nav-lv2-link-active-background-color;

        &:hover {
          color: $js-cat-nav-lv2-link-active-hover-color;
          background: $js-cat-nav-lv2-link-active-hover-background-color;
        }
      }

      &.active:hover > a {
      }

      &.selected > a {
        color: $js-cat-nav-lv2-link-selected-color;
        font-weight: $js-cat-nav-lv2-link-selected-font-weight;
        background: $js-cat-nav-lv2-link-selected-background-color;
        background: $js-cat-nav-lv2-link-selected-background-color;

        &:hover {
          color: $js-cat-nav-lv2-link-selected-hover-color;
          background: $js-cat-nav-lv2-link-selected-hover-background-color;
        }
      }

      &.selected:hover > a {
      }

      &.has-subcategories > a {
        padding-right: $js-cat-nav-subcat-indicator-font-size * 2;
        position: relative;
        z-index: 1;

        &:after {
          content: $js-cat-nav-subcat-indicator-content;
          position: absolute;
          top: 50%;
          right: $js-cat-nav-subcat-indicator-font-size * 0.5;
          margin-top: $js-cat-nav-subcat-indicator-font-size * -0.5;
          font-size: $js-cat-nav-subcat-indicator-font-size;
          font-family: "FontAwesome";
          color: $js-cat-nav-link-color;
          line-height: 1;
        }
      }

      &:hover > a {
        background-color: $js-cat-nav-lv2-link-hover-background-color;
      }

      &.active:hover > a {
        background-color: $js-cat-nav-lv2-link-active-hover-background-color;
      }

      &.selected:hover > a {
        background-color: $js-cat-nav-lv2-link-selected-hover-background-color;
      }

    }
    //
    // SPECIAL POSITIONING
    //
    &.hovered li.lv2:hover .positioner,
    .clicked-once > .positioner,
    &.resizing .positioner {
      display: block !important;
    }

    .moved-horizontally li.lv2 .positioner,
    .lv3-to-left li.lv2 .positioner {
      right: initial;
      left: $js-cat-nav-item-spacing * -1;

      ul.lv3 {
        right: $js-cat-nav-item-spacing;
        left: initial;
      }
    }

    //
    // LV3
    //
    ul.lv3 {
      border: $js-cat-nav-lv3-container-border;
      border-top: 1px solid $js-cat-nav-border-color;
      border-left: 1px solid $js-cat-nav-border-color;

      padding: $js-cat-nav-lv3-container-padding;
      background: $js-cat-nav-lv3-container-background-color;
      box-shadow: $js-cat-nav-lv3-container-shadow;
      position: absolute;
      top: -1px;
      left: $js-cat-nav-item-spacing;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -30px;
        bottom: -30px;
        right: -30px;
        z-index: -1;
      }

      li.lv3 {
        margin: $js-cat-nav-lv3-item-margin;
        line-height: $js-cat-nav-lv3-link-line-height;
        display: block;

        &:last-child  > a {
          border-bottom: 0 solid transparent;
        }

        &.hidden {
          display: none;
        }

        & > a {
          border-bottom: $js-cat-nav-lv3-item-border-bottom;
          padding: $js-cat-nav-lv3-link-padding;
          margin: $js-cat-nav-lv3-link-margin;
          font-size: $js-cat-nav-lv3-link-font-size;
          color: $js-cat-nav-lv3-link-color;
          font-weight: $js-cat-nav-lv3-link-font-weight;
          line-height: $js-cat-nav-lv3-link-line-height;
          background: $js-cat-nav-lv3-link-background-color;
          display: block;
          text-decoration: $js-cat-nav-lv3-link-text-decoration;
          white-space: nowrap;

          &:hover {
            color: $js-cat-nav-lv3-link-hover-color;
            background: $js-cat-nav-lv3-link-hover-background-color;
            text-decoration: $js-cat-nav-lv3-link-hover-text-decoration;
          }
        }

        & + .lv3 > a {
          margin-top: $js-cat-nav-item-spacing;
        }

        &.active > a {
          color: $js-cat-nav-lv3-link-active-color;
          font-weight: $js-cat-nav-lv3-link-active-font-weight;
          background: $js-cat-nav-lv3-link-active-background-color;

          &:hover {
            color: $js-cat-nav-lv3-link-active-hover-color;
            background: $js-cat-nav-lv3-link-active-hover-background-color;
          }
        }

        &.selected > a {
          color: $js-cat-nav-lv3-link-selected-color;
          font-weight: $js-cat-nav-lv3-link-selected-font-weight;
          background: $js-cat-nav-lv3-link-selected-background-color;

          &:hover {
            color: $js-cat-nav-lv3-link-selected-hover-color;
            background: $js-cat-nav-lv3-link-selected-hover-background-color;
          }
        }
      }
    }
    //
    // LV4
    //
    ul.lv4 {
      border: $js-cat-nav-lv4-container-border;
      padding: $js-cat-nav-lv4-container-padding;
      background: $js-cat-nav-lv4-container-background-color;

      li.lv4 {
        margin: $js-cat-nav-lv4-item-margin;
        line-height: $js-cat-nav-lv4-link-line-height;
        display: block;

        &.hidden {
          display: none;
        }

        &:first-child > a {
          border-top: none;
        }

        &:last-child > a {
          border-bottom: 1px solid $js-cat-nav-border-color;
        }

        & > a {
          font-size: $js-cat-nav-lv4-link-font-size;
          padding: $js-cat-nav-lv4-link-padding;
          margin: $js-cat-nav-lv4-link-margin;
          color: $js-cat-nav-lv4-link-color;
          font-weight: $js-cat-nav-lv4-link-font-weight;
          line-height: $js-cat-nav-lv4-link-line-height;
          display: block;
          background: $js-cat-nav-lv4-link-background-color;
          text-decoration: $js-cat-nav-lv4-link-text-decoration;
          white-space: nowrap;
          margin-top: $js-cat-nav-item-spacing;
          border-top: 1px solid $js-cat-nav-border-color;

          &:hover {
            color: $js-cat-nav-lv4-link-hover-color;
            background: $js-cat-nav-lv4-link-hover-background-color;
            text-decoration: $js-cat-nav-lv4-link-hover-text-decoration;
          }
        }

        &.active > a {
          color: $js-cat-nav-lv4-link-active-color;
          font-weight: $js-cat-nav-lv4-link-active-font-weight;
          background: $js-cat-nav-lv4-link-active-background-color;

          &:hover {
            color: $js-cat-nav-lv4-link-active-hover-color;
            background: $js-cat-nav-lv4-link-active-hover-background-color;
          }
        }

        &.selected > a {
          color: $js-cat-nav-lv4-link-selected-color;
          font-weight: $js-cat-nav-lv4-link-selected-font-weight;
          background: $js-cat-nav-lv4-link-selected-background-color;
          display: block;

          &:hover {
            color: $js-cat-nav-lv4-link-selected-hover-color;
            background: $js-cat-nav-lv4-link-selected-hover-background-color;
          }
        }
      }
    }
  }

  //
  // SCROLLED - NOT SCROLLED DEFINITIONS
  //
  .menu-static #cat-nav .nav-bar {
    opacity: 1;
    transition: opacity 0.3s;
  }

  .menu-scrolled #cat-nav .nav-bar {
    position: fixed;
    opacity: 1;
    transition: opacity 0.3s;
    top: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    transition: top 0.3s;
    max-width: $js-max-page-width;
  }

  .menu-scrolled.menu-static #cat-nav .nav-bar {
    top: -100px;
  }

  #menu-overlay {
    display: none;
    opacity: 0;
    //transition: opacity 0.3s;
  }
}
