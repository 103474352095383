//
//  Menu handling
//
@import "../../../scss/foundation/functions";
@import "../../../scss/base_settings";
@import "../../../scss/responsive-base/mixins";


#menu-buttons-wrapper {
  display: none;
}

.header-scrolled #menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  border-bottom: 1px solid #ccc;
}

.header-scrolled.header-static #menu-wrapper {
  top: -120px; //TODO Why hardcoded height? can we do this by measuring?
  opacity: 0;
  //transition: opacity 0s, top 0s;
}

.header-static #menu-wrapper {
  opacity: 1;
  //transition: opacity 0.3s;
}

//
//  Activator icons
//
#menu-content {

  .icon-button {
    display: none
  }

}

//
//  Medium Down
//
@media #{$medium-down} {

  .cart-area-wrapper {
    float: right;
    margin: 0;
    min-width: 0;
    max-width: none;
    padding: 0;

    .small-cart-body {
      border: none;
      color: $js-menu-item-color;
      height: $js-menu-item-height;
      padding: $js-menu-buttons-padding;
      padding-right: 10px; //$js-page-padding-right;
      cursor: pointer;

      &:hover {
        background-color: $js-menu-buttons-wrapper-background-hover-color;
      }

      &::before {
        height: 40px; //$js-menu-item-height;
        line-height: 40px; //$js-menu-item-height;
        color: white;
        font-size: $js-menu-buttons-medium-icon-size;
      }

      .title {
        float: left;
        display: block;
        height: 42px; //$js-menu-item-height;
        line-height: 42px; //$js-menu-item-height;
        font-size: $js-menu-buttons-font-size;
        font-family: $js-menu-buttons-font-family;
        text-transform: $js-menu-text-transform;
        padding: 0 0 0 2px;
        color: $js-menu-item-color;
      }
    }

    .small-cart-items {
      height: $js-menu-item-height;
      line-height: $js-menu-item-height;

      .small-cart-items-value {
        //height: $js-menu-item-height;
        line-height: 20px;
        font-family: $js-menu-buttons-font-family;
        color: $js-menu-item-color;
        padding: 0 5px;
        background-color: white;
        color: $primary-color;
        border-radius: 10px;
        min-width: 16px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px 0 5px;
        text-align: center;
        font-size: 11px;
        float: none;
        height: auto;


        &::before, &::after {
          display: none;
        }
      }
    }

    .small-cart-sum {
      height: $js-menu-item-height;
      display: none;

      .small-cart-sum-value {
        height: $js-menu-item-height;
        line-height: $js-menu-item-height;
        font-size: $js-menu-buttons-font-size;
        font-weight: normal;
        text-transform: $js-menu-text-transform;
      }
    }

    .SmallCartButtons {
      display: none;
    }

    #FreeFreight_Cart {
      display: none;
    }
  }


  #menu-content {
    display: block;
    background-color: $custom-gray; //$js-menu-buttons-wrapper-background-color;
    clear: both;
    float: none;
    width: 100%;
    position: relative;
    padding: 0;
    height: 40px;
    @include clearfix();

    .icon-button {
      font-size: $js-menu-buttons-font-size;
      font-family: $js-menu-buttons-font-family;
      text-transform: $js-menu-text-transform;
      padding: 0 8px 0 8px; //$js-menu-buttons-padding;
      color: $js-menu-item-color;
      cursor: pointer;
      float: left;
      display: block;
      height: 40px; //$js-menu-item-height;
      line-height: 40px; //$js-menu-item-height;

      i {
        //margin-right: 4px;
        font-size: $js-menu-buttons-medium-icon-size;
        float: left;
        height: $js-menu-item-height;
        line-height: $js-menu-item-height;

        &:before {
        }
      }

      span {
        float: left;
        height: $js-menu-item-height;
        line-height: $js-menu-item-height;
      }

      &:hover {
        background-color: $js-menu-buttons-wrapper-background-hover-color;
      }
    }

    #cart-activator {
      display: none !important;
    }

    #menu-activator.icon-button.menu-icon {
      padding-left: ($js-page-padding-left - 4px);
      border-right: 1px solid darken($js-menu-buttons-wrapper-background-color, 10%);
    }
  }

  //
  // SCROLLED - NOT SCROLLED DEFINITIONS
  //

  #menu-wrapper {
    opacity: 0;
    clear: both;
    width: 100%;
  }

  .menu-static #menu-wrapper {
    opacity: 1;
    transition: opacity 0.3s;
    clear: both;
    width: 100%;
    position: relative;
  }

  .menu-scrolled #menu-wrapper {
    position: fixed;
    opacity: 1;
    transition: opacity 0.3s, top 0.3s;
    top: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    z-index: 10;
    height: 40px;

    #menu-content {
      padding: 0;
    }
  }

  .menu-scrolled.menu-static #menu-wrapper {
    top: -100px;
  }

  #menu-buttons-wrapper {
    display: block;
  }
  .menu-component {
    //@include box-shadow(0px,3px,3px, 0px,rgba(0, 0, 0, 0.24));
  }

  // SEARCH
  #search-box-wrapper {
    float: left;
    vertical-align: middle;
    padding: $js-search-box-wrapper-medium-padding;
    margin: $js-search-box-wrapper-medium-margin;
    background-color: $js-search-box-wrapper-medium-background-color;
    height: $js-menu-item-height;
    line-height: $js-menu-item-height;
  }
  .search-box-wrapper {
    padding: 0; // 0 0 5px;
    float: left;
    margin: 0;
    max-width: 0;
    overflow: hidden;
    transition: 0.3s;
    height: $js-menu-item-height;
    line-height: $js-menu-item-height;
    opacity: 0;

    .search-box-header,
    .search-box-footer {
      display: none;
    }

    .search-box-body {
      height: $js-menu-item-height;
      line-height: $js-menu-item-height;
      margin: 0;

      .search-box-input {
        float: none;
        height: $js-menu-item-height;
        line-height: $js-menu-item-height;
        margin-top: 1px;

        input {
          width: $js-search-box-input-medium-width;
          background-color: $js-search-box-input-medium-background-color;
          padding: $js-search-box-input-medium-padding;
          font-size: $js-search-box-input-medium-font-size;
          background-position: $js-search-box-input-medium-background-position;
          border-color: $iron;
          border: none;
          color: $js-body-font-color;
          height: ($js-menu-item-height - 10);
          display: inline-block;
          margin-left: 0;

          &.watermark {
            color: rgba(0, 0, 0, 0);
          }

          &:focus {
            background-color: $js-search-box-input-medium-background-color;
          }
        }
      }

      .search-box-button {
        display: none;
      }
    }
  }

  .search-open {
    #search-box-wrapper {
      background: $js-menu-buttons-wrapper-background-hover-color;
    }
    .search-box-wrapper {
      max-width: 100%;
      opacity: 1;
    }
  }

  .logo-wrapper{
    position: relative;
    margin: 0;
    display: block;
    input {
      max-width: 250px;
    }
    .logo-body{
      float: left;
    }
  }

  .cart-area-wrapper .vat-selector-outer-wrapper{
    display: none;
  }

  #page-list-header {
    display: none;
  }

  #payment-methods{
    display: none;
  }

  #vat-info {
    display: inline-block;
    position: absolute;
    top: -30px;
    float: right;
    right: 15px;
  }

  .cart-area-wrapper {
    position: relative;
    .small-cart-body{
      height: 40px;
      line-height: 40px;
      background: $custom-gray;
    }
  }

}

//
//  Medium Down
//
@media #{$medium-down} {
  .small-cart-body .title {
    display: block;
  }

}

//
//  Small
//
@media #{$small-only} {

  .search-open {
    #search-box-wrapper {
      background: $js-menu-buttons-wrapper-background-hover-color;
      width: 70% !important;
    }
    .search-box-wrapper {
      max-width: 80%;
      opacity: 1;
    }
  }

  .cart-area-wrapper {
    .small-cart-body {
      &::before {
        font-size: $js-menu-buttons-small-icon-size;
      }
    }
  }

  #menu-content {
    .icon-button {
      i {
        font-size: $js-menu-buttons-small-icon-size;
        margin: 0;
      }

      span {
        display: none;
      }
    }

    .small-cart-body .title {
      display: none !important;
    }
  }

  #page-list-header {
    display: none;
  }

  .logo-wrapper input {
    max-width: 250px;
    margin: 0;
  }

  #menu-content {
    padding: 0;
  }

  .logo-wrapper{
    margin: 0;
  }

  #main-area{
    padding-left: 10px;
    padding-right: 10px;
  }

}
