//
//  Start Page
//

.page-default {
  #path-nav {
    display: none;
  }
}

.page-default .startpage-image {
  text-align: center;
  margin-bottom: 12px;
}

.page-default .startpage-text {
  margin-bottom: 4px;
}

#startpage_list {
  ul {
    li {
      .product-info {
        text-transform: none;
        font-weight: bold !important;
        @include product-name-h3
      }
      .product-wrapper {
        .product-image {
          padding-top: $js-startpage-wrapper-image-wrapper-height;
        }
      }
    }
  }
}


.startpage-product-item, .product-outer-wrapper{
  padding: 10px;
}

.startpage-custom-item{
  height: 100%;
  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    img {
      display: block;
      padding: 10px;
      &:hover {
        opacity: 0.9;
      }
    }
    span {
      background: rgba(143, 131, 76, 0.7);
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 10px 15px;
      font-size: 16px;
      color: white;
      text-align: center;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 2)
  }
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 4)
  }
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right-large-up, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
  #startpage_list .items-2 .product-wrapper .product-image {
    padding-top: ($js-startpage-wrapper-image-wrapper-height / 1.4);
  }
}

