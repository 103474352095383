/* SEARCH RESULT
----------------------------------------------------------------------------- */
.search-wrapper {
}

.search-string-result {
  margin: 0 0 20px 0;
}

span.search-string {
  font-style: italic;
  text-decoration: underline;
}

.search-wrapper tr {
  border-bottom: 1px solid white;
}

.search-wrapper tr:first-child, .search-wrapper tr:last-child {
  border-bottom: 0;
}

.search-wrapper td {
  vertical-align: middle;
  padding: 10px;
}

table.search-result-table {
  margin: 0 0 20px 0;
  border-spacing: 10px;
}

table.search-result-table td {
  padding: 5px 5px 5px 0;
}

table.search-result-table tr td:last-child {
  text-align: right;
}

table.search-result-table td.search-order {
  text-align: center;
  .ButtonInfo a{
    background: $custom-blue;
      &:hover{
        background: lighten($custom-blue, 10%);
      }
  }
}

.search-image {
  position: relative;
}

table.search-result-table td.search-thumb {
  max-width: 200px;
}

table.search-result-table .search-image,
table.search-result-table .search-image img {
  max-width: 65px;
  border: 1px solid #666;
}

td.search-productnamne {
  //width: 70px;
  font-weight: bold;
  font-size: 14px;
  a{
    color: $custom-gray;
  }
}

td.search-articlenumber a{
    color: $custom-gray;
}

td.SearchProductPrice {
  white-space: nowrap;

  .SearchPriceStandard{
    color: $custom-gray;
  }
  .SearchPricePrevious{
    display: none;
  }
}

td.search-amount input {
  width: 30px;
  //height: 15px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border: none;
  font-weight: bold;
  color: $custom-gray;
}

td.search-order a {
  float: right;
  width: 86px;
  height: 42px;
  line-height: 22px;
}

//
// Pagination
// 1. Page numbers text
.search-paging {
  a.search-prev, a.search-next {
    @include paging-link-style;
    min-width: $js-paging-control-link-next-prev-min-width;
    text-align: center;
  }
  & td + td { /* 1 */
    font-size: $js-paging-control-link-font-size;
    font-weight: bold;
  }
}

.search-previous-searches {
  .search-previous-searches-label {
    font-weight: 700;
  }
  .search-previous-string {
    margin-right: 8px;
    display: inline-block;
  }
}

.search-table-headers td{
  color: #999;
  font-size: 12px;
}

//
//  Small & Medium Only
//
@media #{$medium-down} {

  table.search-result-table td.search-thumb {
    max-width: 50px;
  }

  table.search-result-table .search-image,
  table.search-result-table .search-image img {
    max-width: 40px;
  }
}

//
//  Medium only
//
@media #{$medium-only} {

  table.search-result-table td.search-thumb {
    max-width: 100px;
  }

  table.search-result-table .search-image,
  table.search-result-table .search-image img
  {
    max-width: 90px;
  }

}
